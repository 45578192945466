function initSearchSelects(datatable) {
    datatable.columns().every( function () {
        var column = this;
        var select = $('<select><option value=""></option></select>')
            .appendTo( $(column.footer()).empty().filter('.filter') )
            .on( 'change', function () {
                var val = $.fn.dataTable.util.escapeRegex(
                    $(this).val()
                );

                column
                    .search( val, false, true )
                    .draw();
            } );



        var set_by_attribute = 0;
        var attribute_data = [];
        column.nodes().flatten().unique().to$().each(function(a, b) {
            var searchdata = b.attributes['data-search'];

            if (typeof searchdata !== "undefined") {

                set_by_attribute = 1;
                if ($.inArray(searchdata.value, attribute_data) == -1) {
                    attribute_data.push(searchdata.value);
                    select.append( '<option value="'+searchdata.value+'">'+searchdata.value+'</option>' )

                }
            }

        });

        if (set_by_attribute == 0) {
            column.data().unique().sort().each( function ( d ) {

                select.append( '<option value="'+d+'">'+d+'</option>' )

            } );
        }


    } );

    datatable.rows().invalidate();
}

var datatable;

$(document).ready(function() {


    datatable = $('.data-table').DataTable({


        "columnDefs": [

            { "orderable": false, "width": "80px", "targets": [ -1 ] }
        ],
        "language": {
            "url": "/admin_assets/js/data_tables/" + phpvar.locale + ".json"
        },
        responsive: true,

        initComplete: function () {
            //datatable = this;
            initSearchSelects(this.api());
        }


    });




});