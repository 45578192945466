$(document).ready(function() {

    $('.edit-gallery-video').on('click', function () {
        var modal = $('#edit-youtube-video-modal');
        $('.edit-youtube-video-loading').show();
        modal.modal('show');


        var video_id = $(this).attr('data-id');

        var url = "/admin/ajax/video-data/" + video_id + "?locale=" + phpvar.locale;
        $.getJSON(url, function(data) {

            if (data.status == 'success') {

                modal.find('input[name="video_id"]').val(video_id);
                modal.find('input[name="video_url"]').val(data.video_url);
                modal.find('input[name="display_order"]').val(data.display_order);

                $('#edit-youtube-video-form').validator('validate');
                $('.edit-youtube-video-loading').hide();

            } else {
                modal.modal('hide');
                swal({
                    title: data.title,
                    text: data.text,
                    type: data.status,
                    timer: 2500,
                    showConfirmButton: false
                });
            }
        });


    });

});
