/**
 * Created by Jerry on 26.10.2015.
 */
$(document).ready(function(){


    function loadOrderItems(data) {


        jPut.orderitems.data = data.rows;

        $('#total-item-price').html(data.total_prices.base_price_wc);
        $('#ship-and-pay-price').html(data.total_prices.ship_pay_price_wc);
        $('#total-tax-price').html(data.total_prices.tax_wc);
        $('#total-price-with-tax').html(data.total_prices.total_price_wc);

        $('.order-items-loading').hide();

        $('a.remove-item-btn').on('click', function(e) {

            e.preventDefault();
            var id = $(this).attr('data-id');

            swal({
                title: phpvar.removePromptTitle,
                text: phpvar.removePromptText,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: phpvar.removePromptSubmit
            }, function () {
                $('.order-items-loading').show();
                removeItemFromCart(id);
            });

        });



    }

    //Load cart on page load
    var order_id = $('input[name="order-id"]').val();
    if (typeof order_id !== 'undefined') {
        var order_items_url = "/admin/ajax/order-data/" + order_id + '/getOrderItems?locale=' + phpvar.locale;
        $.getJSON(order_items_url, function(data) {
            loadOrderItems(data);

        });
    }





    $('#add-to-cart').on('click', function(){
        $('.order-items-loading').show();
        var qty = $('#qty-input').val();
        var id = $(this).attr('data-id');
        var url = "/cart/add/" + id + "?qty=" + qty;
        $(".ajax-loader").fadeTo(500,1);
        $.getJSON(url, function(data) {
            loadOrderItems(data);
            $(".ajax-loader").fadeTo(500,0);
            addedToCartAnim();
        });
    });

    function removeItemFromCart(id) {
        $('.order-items-loading').show();
        var url = "/admin/ajax/order-data/" + order_id + '/removeItem?item_id=' + id + '&locale=' + phpvar.locale;
        $(".ajax-loader").fadeTo(500,1);
        $.getJSON(url, function(data){
            loadOrderItems(data);
            $(".ajax-loader").fadeTo(500,0);
        });
    }




    var timer;
    var delay = 1100;
    $('body').on('input', 'input.order-item-update-qty', function() {
        var id = $(this).attr('data-id');
        var qty = $(this).val();
        clearTimeout(timer);
        timer = setTimeout(function(){
            if (qty === "0") {
                removeItemFromCart(id);
            } else {
                
                var url = "/admin/ajax/order-data/" + order_id + '/updateItemQty?item_id=' + id + '&qty=' + qty + '&locale=' + phpvar.locale;
                $(".ajax-loader").fadeTo(500,1);
                $.getJSON(url, function(data) {
                    loadOrderItems(data);
                    $(".ajax-loader").fadeTo(500,0);
                });
            }
        }, delay);
    });

    var timer_price;
    var delay_price = 1100;
    $('body').on('input', 'input.order-item-update-price', function() {
        var id = $(this).attr('data-id');
        var price = $(this).val();
        clearTimeout(timer_price);
        timer_price = setTimeout(function(){

                var url = "/admin/ajax/order-data/" + order_id + '/updateItemPrice?item_id=' + id + '&price=' + price + '&locale=' + phpvar.locale;
                $(".ajax-loader").fadeTo(500,1);
                $.getJSON(url, function(data) {
                    loadOrderItems(data);
                    $(".ajax-loader").fadeTo(500,0);
                });

        }, delay_price);
    });





    function loadItemsInCategory() {
        $('.add-item-overlay').show();
        var category_id = $('#add_item_category_list').val();

        var url = "/admin/ajax/items-in-category/" + category_id + '?locale=' + phpvar.locale;

        $.getJSON(url, function(data) {


            if (data.status == "success") {


                $('#add_item_item_list').find('option,optgroup').remove();

                $('#add_item_item_list').select2({
                    data: data.item_list
                });
            }
            $('.add-item-overlay').hide();

        });
    }


    $('#add-item-modal').on('show.bs.modal', function (event) {


        loadItemsInCategory();

    });

    $('#add_item_category_list').on('change', function() {
        loadItemsInCategory();
    });

    $('#add-item-to-order').on('click', function() {
        $('#add-item-modal').modal('hide');
        $('.order-items-loading').show();

        var item_id = $('#add_item_item_list').val();

        var url = "/admin/ajax/order-data/" + order_id + '/addItem?item_id=' + item_id + '&locale=' + phpvar.locale;
        $.getJSON(url, function(data) {



            if (data.status == "success") {
                loadOrderItems(data.item_data);
            } else {
                $('.order-items-loading').hide();
            }

            swal({
                title: data.title,
                text: data.text,
                type: data.type,
                timer: 2500,
                showConfirmButton: false
            });

        });



    });

});