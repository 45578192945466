function loadShipAddress(selectbox) {

    var url = phpvar.routeUrl;
    url = url.replace('%5Breplace%5D', selectbox.val());
    $("body").css("cursor", "wait");

    $.getJSON(url, function( data ) {
        $.each( data, function (key, value) {

            $('#ship-addr-form').find('input[name="shipping[' + key + ']"],select[name="shipping[' + key + ']"]').val(value).change();


        });
        $('#new-address').prop('disabled', false);
        $("body").css("cursor", "default");


    });

}

$(document).ready(function() {


    $.datetimepicker.setLocale(phpvar.locale);

    $('.datetimepicker').datetimepicker({
        format:'d.m.Y H:i',
        step: 30
    });

    $('.datepicker').datetimepicker({
        format:'d.m.Y',
        timepicker: false,
        mask:true
    });

    $('.colorpicker').colorpicker();

    $('.js-switch').each(function (key, value) {
        var switchery = new Switchery(value);
    });


    $(".country-select").select2();

    $('#addresses').on('change', function() {

       loadShipAddress($(this));
    });

    $('#new-address').on('click', function() {
        $('#ship-addr-form').find(':text').not(':hidden').val('');
        $('#ship-addr-form').find('input[name="shipping[id]"]').val('');
        $(this).prop('disabled', true);

    });

    $('.ship-addr-delete').on('click', function() {

        swal({
                title: phpvar.deleteTitle,
                text: phpvar.deleteText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: phpvar.deleteBtn,
                cancelButtonText: phpvar.deleteCancelBtn,
                closeOnConfirm: false
            },
            function(){
                var id = $('#addresses').val();
                var url = phpvar.deleteUrl;
                url = url.replace('%5Breplace%5D', id);

                $("body").css("cursor", "wait");
                $.getJSON(url, function( data ) {

                    if (data.status == 'success') {
                        $("#addresses option[value='" + id + "']").remove();
                        loadShipAddress($('#addresses'));
                    }

                    swal({
                            title: data.title,
                            text: data.text,
                            type: data.type,
                            timer: 2500,
                            showConfirmButton: false
                    });




                    $("body").css("cursor", "default");


                });

            });
    });


    $('.reg-type').click(function() {
        if($('#type-company').is(':checked')) {
            $('.company-info-group').show('slow');
        }

        if ($('#type-personal').is(':checked')) {
            $('.company-info-group').slideUp();
        }
    });

    if ($('#type-personal').is(':checked')) {
        $('.company-info-group').hide();
    }

    $('.order-status-select').on('change', function() {

        var select = $(this);
        var order_id = $(this).attr('data-orderid');
        var status_id = $(this).val();

        swal({
            title: phpvar.statusPromptTitle,
            text: phpvar.statusPromptText,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: phpvar.statusPromptSubmit
        }, function () {


            $.ajax({
                type: "POST",
                url: phpvar.orderStatusUrl,
                data: {
                    order_id: order_id,
                    status_id: status_id,
                    locale: phpvar.locale
                },
                dataType: "json",
                success: function(data){
                    console.log(data);
                    swal({
                        title: data.title,
                        text: data.text,
                        html: true,
                        type: data.type,
                        timer: 2500,
                        showConfirmButton: false
                    });

                    $(".left-border-stripe[data-order-id=" + order_id + "]").css('border-left-color', data.color);

                    var selected_text = select.children("option:selected").text();


                    select.parent('td').attr('data-search', selected_text);
                    initSearchSelects(datatable);
                },
                failure: function(errMsg) {
                    alert(errMsg);
                }
            });

        });



    });



});