/**
 * Created by intos on 07.04.2017.
 */

function initCropper(image) {

    if (image.length > 0) {
        image.cropper({
            aspectRatio: 1 / 1,
            responsive: true,
            maxContainerHeight: 300,
            viewMode: 1,
            crop: function(e) {
                // Output the result data for cropping image.
                $('#thumb_x').val(Math.round(e.x));
                $('#thumb_y').val(Math.round(e.y));
                $('#thumb_width').val(Math.round(e.width));
                $('#thumb_height').val(Math.round(e.height));


            }
        });
    }

}

function loadImageData(id) {



}

$(document).ready(function() {

    var URL = window.URL || window.webkitURL;
    var image = $('#img-cropper');
    var thumb_data = [];
    var set_thumb_data;
    var image_id;


    // --- Opening IMG editor for editing image
    $('.open-img-editor').on('click', function (e) {
        e.preventDefault();
        $('.img-editor-loading').show();
        image_id = $(this).data('image-id');
        $('#uploaded-img').val('');
        if (image_id == null) {

            image.cropper('destroy').attr('src', '/img/no-img.jpg');
            initCropper(image);
            $('#image_id').val('');
            $('.img-editor-trans').val('');
            set_thumb_data = false;
            var modal = $('#img-editor');
            modal.find('input[name="display_order"]').val('0');

            $('#img-editor').modal('show');
            $('#img-editor-form').validator('validate');


        } else {
            var url = "/admin/ajax/image-data/" + image_id + "?locale=" + phpvar.locale;
            $.getJSON(url, function(data) {


                if (data.status == 'success') {

                    var modal = $('#img-editor');
                    image.cropper('destroy').attr('src', data.path);
                    initCropper(image);
                    thumb_data = data.thumb_data;

                    $('#image_id').val(image_id);
                    set_thumb_data = true;

                    $('#img-editor').modal('show');


                    $.each(data.translations, function(key, value) {
                        modal.find('input[name="' + key + '"]').val(value);
                    });
                    modal.find('input[name="display_order"]').val(data.display_order);
                    $('#img-editor-form').validator('validate');

                } else {
                    swal({
                        title: data.title,
                        text: data.text,
                        type: data.status,
                        timer: 2500,
                        showConfirmButton: false
                    });
                }
            });
        }





    });



    $('#img-editor').on('shown.bs.modal', function (e) {

        image.cropper('render');
        if (set_thumb_data) {

            image.cropper('setData', thumb_data);
        }
        $('.img-editor-loading').hide();

        $(document).on('dragover', function (e) {
           e.preventDefault();
            $('.image-drop-area').addClass('dragging');
        });

        $(document).on('drop', function (e) {
            e.preventDefault();

            $('.image-drop-area').removeClass('dragging');
        });

        $(document).on('dragleave', function (e) {

            $('.image-drop-area').removeClass('dragging');
        });


        $('.image-drop-area').on("dragover drop", function(e) {
            e.preventDefault();  // allow dropping and don't navigate to file on drop
        }).on("drop", function(e) {
            $("#uploaded-img")
                .prop("files", e.originalEvent.dataTransfer.files);  // put files into element

        });


    })

    var $inputImage = $('#uploaded-img');
    var uploadedImageURL;

    if (URL) {
        $inputImage.on('change', function () {

            var files = this.files;
            var file;

            if (!image.data('cropper')) {
                return;
            }

            if (files && files.length) {
                file = files[0];

                if (/^image\/\w+$/.test(file.type)) {
                    if (uploadedImageURL) {
                        URL.revokeObjectURL(uploadedImageURL);
                    }

                    uploadedImageURL = URL.createObjectURL(file);
                    image.cropper('destroy').attr('src', uploadedImageURL);
                    initCropper(image);

                } else {
                    window.alert('Please choose an image file.');
                    $inputImage.val('');
                }
            }
        });
    } else {
        $inputImage.prop('disabled', true).parent().addClass('disabled');

    }










});