
$(document).ready(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });



    $('.delete-button').on('click', function () {

        var item_link = $(this).attr('data-link');
        swal({
                title: "Opravdu smazat?",
                text: "Po odstranění nebude možné tuto akci vrátit zpět",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Ano, smazat!",
                closeOnConfirm: false
            },
            function(){
                window.location.href = item_link;

            });

    });

    $('.language-tabs').tabs();
    $('.price-tabs').tabs();
    $('.admin-info-tabs').tabs();

    $('.gallery-item-link').magnificPopup({
        type: 'image',
        gallery:{
            enabled:true
        },
        // Delay in milliseconds before popup is removed
        removalDelay: 300,

        // Class that is added to popup wrapper and background
        // make it unique to apply your CSS animations just to this exact popup
        mainClass: 'mfp-fade'
    });

    $('[data-toggle="tooltip"]').tooltip();



});