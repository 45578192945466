/**
 * Created by Jerry on 26.10.2015.
 */
$(document).ready(function(){


    if ($('#category-tree').length > 0) {


        var url = "/admin/ajax/category-array?locale=" + phpvar.locale;
        $.getJSON(url, function(data) {
            $('.category-tree-loading').hide();

                $('#category-tree').tree({
                    data: data,
                    dragAndDrop: true,
                    autoOpen: true,
                    onCreateLi: function(node, $li) {
                        if (node.is_fully_active == false) {
                          $li.find('.jqtree-title').addClass('category-inactive');
                        }

                        $li.find('.jqtree-element').append(
                            '<span class="category-buttons"><a href="#" class="nostyle edit-category-btn" ' +
                            'data-category-id="' + node.id + '"><i class="fa fa-pencil"></i></a>' +
                            ' | <a href="#" class="nostyle delete-category-btn" ' +
                            'data-category-id="' + node.id + '" data-category-name="' + node.name + '"><i class="fa fa-times"></i></a></span>'

                        );

                    }
                });

                $('#category-tree').bind(
                    'tree.move',
                    function(event) {

                        event.preventDefault();

                        var moved_id = event.move_info.moved_node.id;
                        var target_id = event.move_info.target_node.id;
                        var position = event.move_info.position;

                        var move_url = "/admin/ajax/move-category-node?moved_node=" + moved_id + "&target_node=" + target_id + "&position=" + position + "&locale=" + phpvar.locale;

                        $.getJSON(move_url, function(data) {


                            if (data.status == 'success') {
                                event.move_info.do_move();
                            } else {

                                swal({
                                    title: data.title,
                                    text: data.text,
                                    type: 'error',
                                    timer: 2500,
                                    showConfirmButton: false
                                });
                            }


                        });
                    }
                );

        });

        $('body').on('click', '.delete-category-btn', function() {

            var id = $(this).attr('data-category-id');
            var name = $(this).attr('data-category-name');

            swal({
                title: phpvar.removePromptTitle,
                text: phpvar.removePromptText + ' <b>' + name + '</b>?<br>' + phpvar.removePromptText2 + '<br>'+ phpvar.removePromptText3,
                html: true,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: phpvar.removePromptSubmit
            }, function () {
                $('.category-tree-loading').show();

                var delete_url = "/admin/ajax/delete-category/" + id + "&locale=" + phpvar.locale;

                $.getJSON(delete_url, function(data) {

                    $('.category-tree-loading').hide();

                        swal({
                            title: data.title,
                            text: data.text,
                            type: data.status,
                            timer: 2500,
                            showConfirmButton: false
                        });

                    var url = "/admin/ajax/category-array?locale=" + phpvar.locale;
                    $.getJSON(url, function(data) {

                        $('#category-tree').tree('loadData', data);
                    });



                });


            });


        });


        $('body').on('click', '.edit-category-btn', function() {

            var id = $(this).attr('data-category-id');
            $('.edit-loading').show();

            $('#edit-category').modal('show');

            var url = "/admin/ajax/category-data/" + id + "?locale=" + phpvar.locale;
            $.getJSON(url, function(data) {
                $('.edit-loading').hide();

                if (data.status == 'success') {

                    var modal = $('#edit-category');

                    modal.find('#edit-active').prop('checked', data.active);
                    modal.find('#category-id').val(id);

                    $.each(data.translations, function(key, value) {
                        console.log(key, value);
                        modal.find('input[name="' + key + '"]').val(value);
                    });

                } else {
                    swal({
                        title: data.title,
                        text: data.text,
                        type: data.status,
                        timer: 2500,
                        showConfirmButton: false
                    });
                }
            });

        });

        $('#add-category-btn').on('click', function() {


            $('.edit-loading').show();
            $('#edit-category').modal('show');

            var modal = $('#edit-category');

            modal.find(':input:not(input[name="_token"]):not(input[type=submit])').val('');
            modal.find('#edit-active').prop('checked', true);
            modal.find('#category-id').val('');
            $('.edit-loading').hide();



        });

    }




});